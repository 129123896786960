.listItem {
    padding: 8px;
    padding-left: 1.5rem;
    color: #fff;
  }
  
  .listItem:hover,
  .active {
    background-color: rgba(255, 255, 255, 0.08) !important;
  }
  
  a:has(> .listItem) {
    text-decoration: none;
  }
  