.dragger > div:first-of-type {
  background-color: #f0f8ff;
  border: 1px dashed #2483f3;
  border-radius: 4px;
  opacity: 1;
}

.dragger > div:first-of-type > span {
  font-weight: 600;
  font-size: 12px;
  color: #90caf9;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  text-align: center;
}

.placeholder {
  width: 0;
  height: 0;
  display: contents;
}

.dragger > div:nth-child(4) {
  opacity: 0;
}

.disabled {
  opacity: 0.5;
}
